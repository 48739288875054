export default {
  // 登录页
  loginPage: {
    area: 'Area code',
    areaMsg: 'Select area code',
    phone: 'Phone number',
    phoneMsg: 'Please provide your phone number',
    name: 'Name',
    nameMsg: 'Please fill in the nickname',
    password: 'Password',
    passwordMsg: 'Please fill in the password',
    confirmPassword: 'Confirm Password',
    confirmpsd: 'Confirm password',
    code: 'Eeferral Code',
    codeMsg: 'Please fill in the recommendation code',
    termes: 'By registering, you agree to our terms and conditions',
    rappelez: 'Remember me',
    register: 'Register',
    login: 'log in',
    account: 'Do you already have an account?',
    gologing: 'Gologin',
    noAccount: `Don't have an account?`,
    goregister: 'Go register',
    pasdMage: 'Password requires 6-20 letters and numbers',
    loginSuccess: 'Login successful',
    registerSuccess: 'Registration successful, please log in',
    clause: 'Please check the agreement clause!',
    noPasd: 'Password inconsistency!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Please enter the international area code',
    sub: 'Confirm'
  },
  // tabbar页
  tabbarPage: {
    home: 'home',
    order: 'order',
    trade: 'trade',
    team: 'team',
    user: 'user',
  },
  // 首页
  homePage: {
    income: 'Accumulated income',
    myService: 'My service',
    about: 'About',
    partner: 'Cooperative partner',
    title: 'Trading assistant ',
    newStart: 'Start immediately',
    dayHot: `Today's best sellers`,
    price: 'Price',
    daySales: "Day Sale",
    rate: 'Day rate',
    moduleTit1: 'A trading assistant specifically designed for TikTok',
    moduleText1: `TikTok Shop Trading Assistant is an artificial intelligence trading platform focusing on automatic trading. Our platform integrates advanced algorithms and data analysis tools to help users increase profits in the trading market.`,
    moduleTit2: 'Intelligent AI Trading Technology',
    moduleText2: `Use our advanced artificial intelligence technology to accurately analyze market data and execute transactions, realize automated operations and improve trading efficiency.`,
    moduleTit3: 'Diversified trading strategies',
    moduleText3: `We provide a variety of trading strategies to meet the needs of different users, and flexibly adjust strategies according to market changes.`,
    moduleTit4: 'Robust risk management',
    moduleText4: `We are equipped with a strict risk control system to ensure safe and stable transactions and avoid unnecessary risks to user funds.`,
    moduleTit5: 'Professional user support',
    moduleText5: `Our professional team is always ready to provide professional support to users. If you encounter any problems, our customer service team will help you in time.`,
  },
  // 订单页
  orderPage: {
    order: 'Order',
    notFinished: 'Not Finished',
    completed: 'Completed',
    more: `There's nothing more`,
    orderNum: 'Order Number',
    tradeTime: 'Trading Time',
    payTime: 'Payment Time',
    amount: 'Amount',
    newTaskNum: 'Current number of tasks',
    expectedReturns: 'Expected returns',
    noData: 'No data available at the moment',
    placeOrder: 'Place order',
    cancel: 'Cancel',
    close: 'Close',
    noBalance: 'Insufficient Balance',
    needRecharge: 'Need to recharge',
    gorecharge: 'Do you want to go and recharge',
    over: 'Finish'
  },
  // 交易页
  tradePage: {
    title: 'Transaction',
    account: 'Account balance',
    introduceTitle: 'Transaction Introduction',
    introduceText: `TiktokShop uses TiktokShop's feedback every day to showcase the products that TiktokShop sells that need improvement. Users only need to click on the order, and the system will automatically generate an order signature. Users can receive a commission of 0.6% or more each time they pay the order amount through the USDT blockchain, and the system randomly assigns large reward orders.`,
    dayProfit: `Today's earnings`,
    tradeTime: 'Trading Time',
    price: 'Amount',
    profit: 'Expected returns',
    startTrade: 'Start trading',
    close: 'Cancel',
    tIncome: 'Mission Benefits',
    cIncome: 'Commission income',
    match: 'Single start matching',
    Completed: 'Mission accomplished',
    totalTask: 'Total tasks',
    income: 'Current round income',
    lrl: 'Profit Margin',
    ydlr: 'Expected profit',
    ppz: 'Matching',
    trade: 'Trading'
  },
  // 团队页
  teamPage: {
    title: 'Team',
    money: 'Amount',
    commission: 'Commission',
    receive: 'Receive',
    myTeam: 'MyTeam',
    total: 'Total number of people',
    user: 'User',
    contribution: 'Contribution (Task)',
    status: 'Status'
  },
  // 我的页面
  userPage: {
    noLogin: 'Not logged in',
    id: 'ID',
    account: 'My account',
    freezeAmount: 'Freeze account',
    needUop: 'Amount to be recharged',
    withdrawal: 'Withdrawal',
    recharge: 'Recharge',
    invitation: 'Invite your friends',
    invitationText: 'You will receive a corresponding commission for every profit earned after registering as a friend',
    nowInvitation: 'Invite Now',
    userInfo: 'Personal information',
    financial: 'Financial details',
    rechargeRecord: 'Recharge Record',
    withdrawalRecord: 'Withdrawal Record',
    lang: 'Language',
    out: 'Log out',
    copy: 'Copy successful',
    load: 'Loading in progress...',
    outMsg: 'Are you sure you want to log out?',
    msg: 'Trading Assistant, at your service',
    team: 'Team',
    help: 'Help',
    customer: 'Customer service',
  },
  // 提现页面
  withdrawalPage: {
    title: 'Withdrawal',
    mode: 'Payment method',
    address: 'Withdrawal address',
    balance: 'Available balance',
    money: 'Withdrawal amount',
    account: 'Actual Receipt',
    serviceCharge: 'The withdrawal handling fee is',
    submit: 'Submit',
    ipt: 'The withdrawal amount cannot exceed the available balance',
    isSub: 'Awaiting review',
    isAddress: 'Please go to the linked withdrawal address first',
    noAccount: 'Insufficient cash withdrawal amount'
  },
  // 充值页面
  rechargePage: {
    title: 'Recharge',
    submit: 'Submit',
    reMode: 'Recharge method',
    reMoney: 'Recharge amount',
    certificate: 'Upload Credentials',
    moneyMsg: 'Please enter the amount',
    qrCode: 'Scan code to recharge',
    copyClick: 'Click to copy',
    copy: 'Copy successful',
    success: 'Recharge successful',
    submitMsg: 'Pending',
    jg: 'If you need other payment channels, please contact customer service',
    lxkf: 'customer service',
    text1: `If you haven't received payment for a long time, please refresh the page.`,
    text2: 'If you have any recharge questions, please contact our online customer service.',
    load: 'Uploading...',
    loadSucess: 'Upload successful',
    loadError: 'Upload failed, please try again',
    isPz: 'Please upload the certificate',
    rechargeMsg: 'Please choose the following method to transfer an equivalent amount to avoid delays in financial checks',
    detail: 'Recharge details',
    orderId: 'Order number',
    time: 'Time',
    plase: 'Please use the account',
    tran: 'Transfer',
    toAcc: 'To the account',
    newAccount: 'The amount currently received',
    results: 'Obtain the results'
  },
  //邀请好友页
  InvitePage: {
    title: 'Invite friends',
    InviteCode: 'Invitation code',
    InviteText: 'Invite friends and enjoy large profits. Go invite them now',
    copyClick: 'Click to copy',
    copy: 'Copy successful',
    text1: 'Let us work together to bring the TikTok Shop Transaction Assistant to the world and make everyone feel happy and rewarded.',
    text2: 'Invite friends and get a 15% bonus on their earnings!',
    text3: 'Invite Link',
    btn: 'Copy invitation link'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Personal information',
    phone: 'Phone',
    pasd: 'Login password',
    edit: 'Modify',
    yPasd: 'Please enter the original password',
    yPasdMsg: 'The original password cannot be empty!',
    newPasd: 'Please enter a new password',
    newPasdMsg: 'The new password cannot be empty',
    nowPasd: 'Confirm password',
    nowPasdMsg: 'Confirm password cannot be empty',
    close: 'Cancel',
    submit: 'Determine',
    address: 'Please enter the address for modification',
    sucess: 'Successfully modified withdrawal address',
    goLogin: 'Password changed successfully, please log in again',
  },
  // 资金明细页
  financialPage: {
    title: 'Financial details',
    noMore: `There's nothing more`,
    amount: 'Amount',
    recharge: 'Recharge',
    orderSy: 'income',
    distributionSy: 'Distribution revenue',
    other: 'Other',
    payment: 'Pay Order',
    withdrawal: 'Withdrawal',
    frozen: 'Frozen',
    time: 'Time',
    noData: 'No data available at the moment',
    refresh: 'Refresh successful',
    Withdrawn: 'Withdrawn',
    shsb: 'Audit failed',
    shz: 'Under review'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'All',
    tab2: 'Top up',
    tab3: 'Withdrawal',
    tab4: 'Income',
    title: 'Recharge Record',
    noMore: `There's nothing more`,
    amount: 'Amount',
    pendingPayment: 'Pending',
    paymentSec: 'Successful',
    paymentFail: 'Audit failed',
    refund: 'Tefund',
    time: 'Time',
    noData: 'No data available at the moment',
    refresh: 'Refresh successful'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Withdrawal record',
    noMore: `There's nothing more`,
    amount: 'Amount',
    withdrawal: 'Withdrawal',
    time: 'Time',
    noData: 'No data available at the moment',
    refresh: 'Refresh successful'
  }
}