export default {
  // 登录页
  loginPage: {
    area: 'Gebietscode',
    areaMsg: 'Bitte einen Gebietscode auswählen',
    phone: 'Handy',
    phoneMsg: 'Bitte Handynummer eingeben',
    name: 'Nickname',
    nameMsg: 'Bitte Nickname eingeben',
    password: 'Passwort',
    passwordMsg: 'Bitte Passwort eingeben',
    confirmPassword: 'Passwort bestätigen',
    confirmpsd: 'Passwort bestätigen', // 注意：这里与confirmPassword重复，建议统一使用一个
    code: 'Empfehlungscode',
    codeMsg: 'Bitte Empfehlungscode eingeben',
    termes: 'Mit der Registrierung erklären Sie sich mit unsere Allgemeine Geschäftsbedingungen einverstanden',
    rappelez: 'Angemeldet bleiben',
    register: 'Registrieren',
    login: 'Anmelden',
    account: 'Bereits ein Konto?',
    gologing: 'Zum Anmelden gehen',
    noAccount: 'Kein Konto?',
    goregister: 'Zum Registrieren gehen',
    pasdMage: 'Passwort muss 6-20 Buchstaben und Zahlen enthalten',
    loginSuccess: 'Anmeldung erfolgreich',
    registerSuccess: 'Registrierung erfolgreich, bitte anmelden',
    clause: 'Bitte die Allgemeine Geschäftsbedingungen akzeptieren!',
    noPasd: 'Passwörter sind nicht identisch!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Bitte internationalen Gebietscode eingeben',
    sub: 'Bestätigen'
  },
  // tabbar页
  tabbarPage: {
    home: 'Startseite',
    order: 'Bestellungen',
    trade: 'Handel',
    team: 'Team',
    user: 'Nutzer',
  },
  // 首页
  homePage: {
    income: 'Kumulierte Einnahmen',
    myService: 'Mein Dienst',
    about: 'Über uns',
    partner: 'Partner',
    title: 'Handelsassistent',
    newStart: 'Jetzt starten',
    dayHot: 'Heute beliebte Produkte',
    price: 'Preis',
    daySales: 'Verkauf',
    rate: 'Zinssatz',
    moduleTit1: 'Handelsassistent speziell für TikTok',
    moduleText1: 'TikTok Shop Handelsassistent ist ein KI-basiertes Handelsplattform, die fortschrittliche Algorithmen und Datenanalysewerkzeuge integriert, um Nutzern zu helfen, ihre Gewinne in den Handelsmärkten zu erhöhen.',
    moduleTit2: 'Intelligente KI-Handelstechnologie',
    moduleText2: 'Mit unserer fortschrittliche KI-Technologie analysieren wir präzis Marktdaten und ausführen Transaktionen, um eine automatisierte Operation zu erreichen und die Handelseffizienz zu erhöhen.',
    moduleTit3: 'Vielfältige Handelsstrategien',
    moduleText3: 'Wir bieten verschiedene Handelsstrategien, um die Bedürfnisse verschiedener Nutzer zu erfüllen, und flexibel die Strategien gemäß Marktänderungen anzupassen.',
    moduleTit4: 'Stabile Risikomanagement',
    moduleText4: 'Wir verfügen über einen strengen Risikokontrollsystem, um die Sicherheit und Stabilität des Handels zu gewährleisten und zu vermeiden, dass die Gelder der Nutzer unnötigen Risiken ausgesetzt werden.',
    moduleTit5: 'Professionelle Nutzerunterstützung',
    moduleText5: 'Unser professionelles Team ist jederzeit bereit, die Nutzer zu unterstützen. Wenn Sie irgendwelche Probleme haben, unser Kundendienstteam wird Ihnen zeitnah Hilfe zur Verfügung stellen.',
  },
  // 订单页
  orderPage: {
    order: 'Bestellung',
    notFinished: 'Unvollständig',
    completed: 'Abgeschlossen',
    more: 'Keine weiteren',
    orderNum: 'Bestellnummer',
    tradeTime: 'Handelzeitpunkt',
    payTime: 'Zahlungszeitpunkt',
    amount: 'Betrag',
    newTaskNum: 'Aktuelle Aufgabenanzahl',
    expectedReturns: 'Erwartete Rendite',
    noData: 'Keine Daten vorhanden',
    placeOrder: 'Bestellung aufgeben',
    cancel: 'Abbrechen',
    close: 'Schließen',
    noBalance: 'Guthaben nicht ausreichend',
    needRecharge: 'Aufladung erforderlich',
    gorecharge: 'Soll ich zur Aufladung gehen?',
    over: 'Abgeschlossen'
  },
  // 交易页
  tradePage: {
    title: 'Handel',
    account: 'Kontostand',
    introduceTitle: 'Handelseinführung',
    introduceText: 'TiktokShop nutzt täglich die Rückmeldungen von TiktokShop, um die Produkte zu präsentieren, die bei TiktokShop verkauft werden und Verbesserungen bedürfen. Nutzer müssen nur auf eine Bestellung klicken, und das System automatisch eine Bestellungssignatur generiert. Nutzer die Bestellungsmenge über die USDT-Blockchain zahlen, erhalten jedes Mal eine Kommission von mehr als 0.6% und das System zufällig großzügige Belohnungsbestellungen zuordnet.',
    dayProfit: 'Heutige Gewinn',
    tradeTime: 'Handelzeitpunkt',
    price: 'Betrag',
    profit: 'Erwartete Rendite',
    startTrade: 'Handel starten',
    close: 'Abbrechen',
    tIncome: 'Kumulierte Aufgabenrendite',
    cIncome: 'Kumulierte Kommissionsrendite',
    match: 'Einmalige Startabgleich',
    Completed: 'Aufgabe abgeschlossen',
    totalTask: 'Gesamtaufgabenanzahl',
    income: 'Aktuelle Einnahmen',
    lrl: 'Rentabilitätsrate',
    ydlr: 'Erwartete Rendite',
    ppz: 'Im Abgleich',
    trade: 'Im Handel'
  },
  // 团队页
  teamPage: {
    title: 'Team',
    money: 'Betrag',
    commission: 'Kommission',
    receive: 'Empfangen',
    myTeam: 'Mein Team',
    total: 'Gesamtanzahl',
    user: 'Nutzer',
    contribution: 'Beitrag (Aufgabe)',
    status: 'Status'
  },
  // 我的页面
  userPage: {
    noLogin: 'Nicht angemeldet',
    id: 'Nummer',
    account: 'Mein Konto',
    freezeAmount: 'Einfrierbetrag',
    needUop: 'Aufladung erforderlich',
    withdrawal: 'Auszahlung',
    recharge: 'Aufladung',
    invitation: 'Freunde einladen',
    invitationText: 'Wenn Freunde sich registrieren, verdienen Sie mit jeder Gewinn, die sie verdienen, eine entsprechende Kommission',
    nowInvitation: 'Jetzt einladen',
    userInfo: 'Persönliche Informationen',
    financial: 'Finanzübersicht',
    rechargeRecord: 'Aufladungsaufzeichnung',
    withdrawalRecord: 'Auszahlungsaufzeichnung',
    lang: 'Sprache',
    out: 'Abmelden',
    copy: 'Kopiert erfolgreich',
    load: 'Lädt...',
    outMsg: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
    msg: 'Handelsassistent, zu Ihren Diensten',
    team: 'Team',
    help: 'Hilfe',
    customer: 'Kundendienst',
  },
  // 提现页面
  withdrawalPage: {
    title: 'Geldabhebung',
    mode: 'Zahlungsmethode',
    address: 'Abhebeadresse',
    balance: 'Verfügbares Guthaben',
    money: 'Abhebebetrag',
    account: 'Effektiv empfangenes Betrag',
    serviceCharge: 'Abhebegebühr beträgt',
    submit: 'Absenden',
    ipt: 'Der Abhebebetrag darf nicht größer sein als das verfügbares Guthaben',
    isSub: 'In Bearbeitung',
    isAddress: 'Bitte zuerst eine Abhebeadresse binden',
    noAccount: 'Nicht genügend verfügbares Guthaben für die Abhebung'
  },
  // 充值页面
  rechargePage: {
    title: 'Guthabenaufladung',
    submit: 'Absenden',
    reMode: 'Aufladungsweise',
    reMoney: 'Aufladungsbetrag',
    certificate: 'Anmeldeinformationen hochladen',
    moneyMsg: 'Bitte einen Betrag eingeben',
    qrCode: 'QR-Code-Aufladung',
    copyClick: 'Klicken Sie zum Kopieren',
    copy: 'Kopiert',
    success: 'Aufladung erfolgreich',
    submitMsg: 'Erfolgreich abgesendet, in Bearbeitung',
    jg: 'Für andere Zahlungskanäle kontaktieren Sie bitte den Kundenservice',
    lxkf: 'Kundenservice kontaktieren',
    text1: 'Wenn Sie lange keine Zahlung erhalten, bitte die Seite aktualisieren.',
    text2: 'Für alle Aufladungsprobleme kontaktieren Sie bitte unseren Online-Kundenservice.',
    Load: 'Hochladen...',
    loadSucess: 'Upload erfolgreich',
    loadError: `Hochladen fehlgeschlagen, bitte versuchen Sie es erneut`,
    isPz: 'Bitte laden Sie das Zertifikat hoch',
    rechargeMsg: "Bitte wählen Sie die folgende Methode, um einen gleichwertigen Betrag zu überweisen und Verzögerungen in den Finanzprüfungen zu vermeiden.",
    detail: "Aufladedetails",
    orderId: "Auftragsnummer",
    time: "Zeit",
    plase: 'Bitte Nutzerkonto verwenden',
    tran: 'Einzahlung',
    toAcc: 'auf Konto',
    newAccount: 'Aktuell erhaltener Betrag',
    results: 'Ergebnis erhalten'
  },
  //邀请好友页
  InvitePage: {
    title: 'Freunde einladen',
    InviteCode: 'Einladungscode',
    InviteText: 'Freunde einladen und große Erträge erhalten. Los gehts und einladen Sie!',
    copyClick: 'Klicken Sie zum Kopieren',
    copy: 'Kopiert',
    text1: 'Lassen Sie uns gemeinsam TikTok Shop Transaction Assistant der Welt präsentieren und jedem Freude und Belohnungen bringen.',
    text2: 'Für die Einladung von Freunden erhalten Sie 15% Bonus von ihren Einnahmen!',
    text3: 'Einladungslink',
    btn: 'Einladungslink kopieren'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Persönliche Informationen',
    phone: 'Handynummer',
    pasd: 'Anmeldepasswort',
    edit: 'Bearbeiten',
    yPasd: 'Bitte das Altes Passwort eingeben',
    yPasdMsg: 'Das Altes Passwort darf nicht leer sein!',
    newPasd: 'Bitte das Neues Passwort eingeben',
    newPasdMsg: 'Das Neues Passwort darf nicht leer sein',
    nowPasd: 'Passwort bestätigen',
    nowPasdMsg: 'Die Passwortbestätigung darf nicht leer sein',
    close: 'Abbrechen',
    submit: 'Bestätigen',
    address: 'Bitte die neue Adresse eingeben',
    sucess: 'Die Abhebeadresse wurde erfolgreich bearbeitet',
    goLogin: 'Das Passwort wurde erfolgreich bearbeitet. Bitte erneut anmelden',
  },
  // 资金明细页
  financialPage: {
    title: 'Finanzübersicht',
    noMore: 'Keine mehr',
    amount: 'Betrag',
    recharge: 'Aufladung',
    orderSy: 'Ordererträge',
    distributionSy: 'Vertriebserträge',
    other: 'Andere',
    payment: 'Bestellung bezahlen',
    withdrawal: 'Geldabhebung',
    frozen: 'Einfrieren',
    time: 'Zeit',
    noData: 'Keine Daten vorhanden',
    refresh: 'Erfolgreich aktualisiert',
    Withdrawn: 'Abheben erfolgreich',
    shsb: 'Ablehnung der Prüfung',
    shz: 'In Prüfung'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'Alle',
    tab2: 'Aufladung',
    tab3: 'Abhebung',
    tab4: 'Erträge',
    title: 'Aufladungsaufzeichnungen',
    noMore: 'Keine mehr',
    amount: 'Betrag',
    pendingPayment: 'In Prüfung',
    paymentSec: 'Prüfung erfolgreich',
    paymentFail: 'Prüfung fehlgeschlagen',
    refund: 'Rückerstattung',
    time: 'Zeit',
    noData: 'Keine Daten vorhanden',
    refresh: 'Erfolgreich aktualisiert'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'Abhebungsaufzeichnungen',
    noMore: 'Keine mehr',
    amount: 'Betrag',
    withdrawal: 'Abhebung',
    time: 'Zeit',
    noData: 'Keine Daten vorhanden',
    refresh: 'Erfolgreich aktualisiert'
  }
}