// vue-i18n所需要引入的
import { createI18n } from 'vue-i18n'

// 本地的文件夹
import zhLocale from './zh-CN'
import enLocale from './en-US'
import jajpLocale from './ja-JP'
import KokrLocale from './ko-KR'
import frfrLocale from './fr-FR'
import deLocale from './de-DE'
import itLocale from './it-IT'
import ruLocale from './ru-RU'
import thLocale from './th-TH'
// vant所需要引入的
import { Locale } from 'vant'
//vant中的文件夹  需要的语言和本地的语言保持一致
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import koKR from 'vant/lib/locale/lang/ko-KR'
import frFR from 'vant/lib/locale/lang/fr-FR'
import deDe from 'vant/lib/locale/lang/de-DE'
import itIT from 'vant/lib/locale/lang/it-IT'
import ruRU from 'vant/lib/locale/lang/ru-RU'
import thTH from 'vant/lib/locale/lang/th-TH'
const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  jp: {
    ...jaJP,
    ...jajpLocale
  },
  kor: {
    ...koKR,
    ...KokrLocale
  },
  fr: {
    ...frFR,
    ...frfrLocale
  },
  de: {
    ...deDe,
    ...deLocale
  },
  it: {
    ...itIT,
    ...itLocale
  },
  ru: {
    ...ruRU,
    ...ruLocale
  },
  th: {
    ...thTH,
    ...thLocale
  },
}

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  } else if (lang === 'jp') {
    Locale.use(lang, jaJP)
  } else if (lang === 'kor') {
    Locale.use(lang, koKR)
  } else if (lang === 'fr') {
    Locale.use(lang, frFR)
  } else if (lang === 'de') {
    Locale.use(lang, deDe)
  } else if (lang === 'it') {
    Locale.use(lang, itIT)
  } else if (lang === 'ru') {
    Locale.use(lang, ruRU)
  } else if (lang === 'th') {
    Locale.use(lang, thTH)
  }
}
// 获取浏览器的语言
// const lang = (navigator.language || 'en').toLocaleLowerCase()
// const language = localStorage.getItem('language') || lang.split('-')[0] || 'en';
const language = 'ru'// 默认设置英文
localStorage.setItem('language', language)
const i18n = createI18n({
  //Not available in legacy mode 解决报错问题的配置项！！！
  legacy: false,
  // 全局注册 $t方法
  globalInjection: true,
  //设置初始化语言
  locale: language,
  // 设置备用语言
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: false,
  fallbackWarn: false
})
export { i18n, vantLocales }

