export default {
  // 登录页
  loginPage: {
    area: 'Регион',
    areaMsg: 'Выберите регион',
    phone: 'Мобильный телефон',
    phoneMsg: 'Пожалуйста, введите мобильный номер',
    name: 'Никнейм',
    nameMsg: 'Пожалуйста, введите никнейм',
    password: 'Пароль',
    passwordMsg: 'Пожалуйста, введите пароль',
    confirmPassword: 'Подтвердить пароль',
    confirmpsd: 'Подтвердить пароль', // Замечание: "confirmpsd" скорее всего ошибка, и она должна быть "confirmPassword" для согласованности
    code: 'Рекомендательный код',
    codeMsg: 'Пожалуйста, введите рекомендательный код',
    termes: 'Регистрация означает, что вы согласны с нашими условиями и положениями',
    rappelez: 'Запомнить меня',
    register: 'Регистрация',
    login: 'Войти',
    account: 'У вас уже есть аккаунт?',
    gologing: 'Перейти к вход',
    noAccount: 'Нет аккаунта?',
    goregister: 'Перейти к регистрации',
    pasdMage: 'Пароль должен содержать 6-20 букв и цифр',
    loginSuccess: 'Успешная авторизация',
    registerSuccess: 'Регистрация прошла успешно, пожалуйста, войдите',
    clause: 'Пожалуйста, отметьте, что согласны с условиями!',
    noPasd: 'Пароли не совпадают!'
  },
  // 区号选择
  areaPage: {
    searchMsg: 'Пожалуйста, введите международный код региона',
    sub: 'Ок'
  },
  // tabbar页
  tabbarPage: {
    home: 'Главная',
    order: 'Заказы',
    trade: 'Торговля',
    team: 'Команда',
    user: 'Пользователь'
  },
  // 首页
  homePage: {
    income: 'Накопленная прибыль',
    myService: 'Мои услуги',
    about: 'О нас',
    partner: 'Партнеры',
    title: 'Помощник по торговле',
    newStart: 'Начать сейчас',
    dayHot: 'Самые продаваемые товары сегодня',
    price: 'Цена',
    daySales: 'Распродажа',
    rate: 'темп роста',
    moduleTit1: 'Помощник по торговле, специально для TikTok',
    moduleText1: 'TikTok Shop Помощник по торговле - это искусственно-интеллектуальная платформа для автоматизированных торговых операций, наша платформа интегрировала передовые алгоритмы и инструменты для анализа данных, помогая пользователям увеличивать прибыль на торговых рынках.',
    moduleTit2: 'Интеллектуальная технология автоматизированных торговых операций',
    moduleText2: 'Мы используем передовые технологии искусственного интеллекта, точно анализируем рыночные данные и выполняем торговые операции, автоматизируя процесс и повышая эффективность торговли.',
    moduleTit3: 'Разнообразные торговые стратегии',
    moduleText3: 'Мы предлагаем разнообразные торговые стратегии, удовлетворяя потребности разных пользователей, и гибко адаптируем стратегии в соответствии с изменениями рынка.',
    moduleTit4: 'Стабильная управление рисками',
    moduleText4: 'Мы оснащены строгой системой контроля рисками, гарантируя безопасность и стабильность торговли, избегая необходимых рисков для капитала пользователя.',
    moduleTit5: 'Профессиональная поддержка пользователей',
    moduleText5: 'Наша профессиональная команда готова в любой момент оказать пользователям профессиональную поддержку, если вы столкнулись с какими-либо проблемами, наша команда поддержки всегда будет готова вам помочь.'
  },
  // 订单页
  orderPage: {
    order: 'Заказы',
    notFinished: 'Незавершенные',
    completed: 'Завершенные',
    more: 'Больше нет',
    orderNum: 'Номер заказа',
    tradeTime: 'Время торговли',
    payTime: 'Время оплаты',
    amount: 'Сумма',
    newTaskNum: 'Количество текучих задач',
    expectedReturns: 'Ожидаемая прибыль',
    noData: 'Нет данных',
    placeOrder: 'Оформить заказ',
    cancel: 'Отменить',
    close: 'Закрыть',
    noBalance: 'Недостаточно средств на счет',
    needRecharge: 'Нужно пополнить счет',
    gorecharge: 'Перейти к пополнению?',
    over: 'Завершено'
  },
  // 交易页
  tradePage: {
    title: 'Торговля',
    account: 'Остаток на счет',
    introduceTitle: 'Внедрение торговли',
    introduceText: 'TiktokShop ежедневно использует отзывы TiktokShop для демонстрации продукции TiktokShop, которая нуждается в улучшении. Пользователь просто кликает по заказу, система автоматически генерирует подписи заказа. Пользователь оплачивает сумма заказа через блокчейн USDT, получая каждый раз более 0.6% комиссии, система рандомно распределяет крупные вознаграждения заказа.',
    dayProfit: 'Сегодняшняя прибыль',
    tradeTime: 'Время торговли',
    price: 'Сумма',
    profit: 'Ожидаемая прибыль',
    startTrade: 'Начать торговля',
    close: 'Отменить',
    tIncome: 'Накопленная прибыль задач',
    cIncome: 'Накопленная комиссия',
    match: 'Однократное запуск сопоставления',
    Completed: 'Задача завершена',
    totalTask: 'Всего задач',
    income: 'Прибыль этого раунда',
    lrl: 'Уровень рентабельности',
    ydlr: 'Ожидаемая рентабельность',
    ppz: 'В процессе сопоставления',
    trade: 'В процессе торговли'
  },
  // 团队页
  teamPage: {
    title: 'Команда',
    money: 'Сумма',
    commission: 'Комиссия',
    receive: 'Получить',
    myTeam: 'Моя команда',
    total: 'Всего людей',
    user: 'Пользователь',
    contribution: 'Вклад (задачи)',
    status: 'Статус'
  },
  // 我的页面
  userPage: {
    noLogin: 'Не авторизирован',
    id: 'Идентификатор',
    account: 'Мой аккаунт',
    freezeAmount: 'Заблокированная сумма',
    needUop: 'Сумма для пополнения',
    withdrawal: 'Снять наличные',
    recharge: 'Пополнить счет',
    invitation: 'Пригласить ваших друзей',
    invitationText: 'Каждая прибыль, заработанная вашими друзьями после регистрации, принесет вам соответствующую комиссию',
    nowInvitation: 'Пригласить сейчас',
    userInfo: 'Личная информация',
    financial: 'Финансовый отчет',
    rechargeRecord: 'История пополнения',
    withdrawalRecord: 'История вывода средств',
    lang: 'Язык',
    out: 'Выйти',
    copy: 'Копирование успешно',
    load: 'Загрузка...',
    outMsg: 'Вы уверены, что хотите выйти из системы?',
    msg: 'Помощник по транзакциям, в вашем служении',
    team: 'Команда',
    help: 'Помощь',
    customer: 'Служба поддержки клиентов'
  },
  // 提现页面
  withdrawalPage: {
    title: 'Вывести средства',
    mode: 'Способ вывода',
    address: 'Адрес вывода',
    balance: 'Доступный остаток',
    money: 'Сумма вывода',
    account: 'Фактическая сумма на счет',
    serviceCharge: 'Комиссия по выводу составляет',
    submit: 'Отправить',
    ipt: 'Сумма вывода не должна быть больше доступного остатка',
    isSub: 'Ожидает проверки',
    isAddress: 'Сначала привязать адрес вывода',
    noAccount: 'Недостаточно средств для вывода'
  },
  // 充值页面
  rechargePage: {
    title: 'Пополнение счета',
    submit: 'Отправить',
    reMode: 'Способ пополнения',
    reMoney: 'Сумма пополнения',
    certificate: 'Загрузить документ',
    moneyMsg: 'Пожалуйста, введите сумма',
    qrCode: 'Пополнение по QR-коду',
    copyClick: 'Нажмите, чтобы скопировать',
    copy: 'Копирование успешно',
    success: 'Пополнение успешно',
    submitMsg: 'Отправлено успешно, в процессе проверки',
    jg: 'Если нужен другой способ оплаты, связаться с службой поддержки',
    lxkf: 'Связаться с службой поддержки',
    text1: 'Если оплата не поступила в течение длительного времени, попробуйте обновить страницу.',
    text2: 'Если есть какие-либо проблемы с пополнением, связаться с нами онлайн.',
    load: 'Загрузка...',
    loadSucess: 'Загрузка успешна',
    loadError: 'Загрузка неудачна, попробуйте еще раз',
    isPz: 'Пожалуйста, загрузите документ',
    rechargeMsg: 'Пожалуйста, перевести эквивалентную сумма по одному из способов, чтобы избежать задержек в финансовой проверке',
    detail: 'Подробности пополнения',
    orderId: 'Номер заказа',
    time: 'Время',
    plase: 'Пожалуйста, используйте аккаунт',
    tran: 'Перевод денег',
    toAcc: 'на аккаунт',
    newAccount: 'Сумма, полученная на данный момент',
    results: 'Получение результата'
  },
  // 邀请好友页
  InvitePage: {
    title: 'Пригласить друг',
    InviteCode: 'Приглашенный код',
    InviteText: 'Пригласите друзья, получите крупную выгоду. Пригласите сейчас!',
    copyClick: 'Нажмите, чтобы скопировать',
    copy: 'Копирование успешно',
    text1: 'Давайте вместе распространять TikTok Shop Помощник по транзакциям по всему миру, чтобы каждый почувствовал радость и вознаграждение.',
    text2: 'Приглашенный друг даст вам 15% от его доходов!',
    text3: 'Приглашенная ссылка',
    btn: 'Копировать приглашенную ссылку'
  },
  // 个人信息页
  userInfoPage: {
    title: 'Лицная информация',
    phone: 'Номер телефона',
    pasd: 'Пароль для входа',
    edit: 'Редактировать',
    yPasd: 'Пожалуйста, введите старый пароль',
    yPasdMsg: 'Старый пароль не должен быть пустым!',
    newPasd: 'Пожалуйста, введите новый пароль',
    newPasdMsg: 'Новый пароль не должен быть пустым',
    nowPasd: 'Подтвердить пароль',
    nowPasdMsg: 'Подтверждение пароля не должно быть пустым',
    close: 'Отменить',
    submit: 'Ок',
    address: 'Пожалуйста, введите адрес для изменения',
    sucess: 'Адрес вывода успешно изменен',
    goLogin: 'Пароль успешно изменен, попробуйте войти еще раз',
  },
  // 资金明细页
  financialPage: {
    title: 'Подробности финансов',
    noMore: ' Больше нет',
    amount: 'Сумма',
    recharge: 'Пополнение',
    orderSy: 'Доход по заказу',
    distributionSy: 'Доход по дистрибуции',
    other: 'Прочее',
    payment: 'Оплата заказа',
    withdrawal: 'Вывод средств',
    frozen: 'Заблокировано',
    time: 'Время',
    noData: 'Нет данных',
    refresh: 'Обновлено успешно',
    Withdrawn: 'Выведено',
    shsb: 'неудача',
    shz: 'проверка'
  },
  // 充值记录页面
  rechargeRecordPage: {
    tab1: 'Все',
    tab2: 'Пополнение',
    tab3: 'снять',
    tab4: 'Доход',
    title: 'История пополнения',
    noMore: ' Больше нет',
    amount: 'Сумма',
    pendingPayment: 'проверка',
    paymentSec: 'успех',
    paymentFail: 'неудача',
    refund: 'Возврат средств',
    time: 'Время',
    noData: 'Нет данных',
    refresh: 'Обновлено успешно'
  },
  // 提现记录
  withdrawalRecordPage: {
    title: 'История вывода средств',
    noMore: ' Больше нет',
    amount: 'Сумма',
    withdrawal: 'Вывод средств',
    time: 'Время',
    noData: 'Нет данных',
    refresh: 'Обновлено успешно'
  }
}